@import '../functions';
@import '../variables';
@import '../mixins';

.home{

	&.universes{
		text-align: center;
		padding: $spacer * 3 0 $spacer * 5;

		h3{
			font-size: rem(18);
		}

		@include media-breakpoint-down(sm) {
			h3{margin: 0 0 $spacer * 2;}
		}

		@include media-breakpoint-up(md) {
			h3{margin: 0 0 $spacer * 4;}
		}


		ul.universes-container{
			list-style: none;
			margin: 0;
			padding: 0 $spacer;

			li{
				display: inline-block;
				padding-bottom: $spacer / 2;

				a{
					display: block;
					padding: 4px 20px;
					background-color: $white;
					border-radius: 30px;
					box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
					color: $secondary;
					font-weight: 600;
					text-decoration: none;
					@include transition;

					@include media-breakpoint-only(xs) {
						margin: 0 $spacer / 3 $spacer / 3;
					}

					@include media-breakpoint-up(sm) {
						margin: 0 $spacer / 2 $spacer / 2;
					}

					&:hover{
						background-color: $lighter;
						color: $dark;
					}
				}
			}
		}
	}

	&.stories,
	&.newsletter,
	&.testimonials{
		.title{

			h2{
				font-family: $font-family-script;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
				text-align: center;
				margin-bottom: $spacer * 3;

				h1,
				h2{display: inline;}

				h1{font-size: rem(24);}

				h2{
					font-size: 1.5em;
					margin-left: $spacer / 2;
					position: relative;
					top: 10px;
				}

			}

			@include media-breakpoint-up(md) {
				h2{
					font-size: rem(30);
					line-height: 1;
				}

				h2{
					margin-top: $spacer * 2;
					margin-left: $spacer * 3;
				}
			}
		}
	}

	&.stories{

		.stories-wrapper {
			width: 90%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			padding-bottom: $spacer * 4;
			max-width: 1120px;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
				align-items: center;

				.stories-container{
					width: calc(240px + #{$spacer} + 2px);
				}
			}

			@include media-breakpoint-up(md) {
				.title{
					padding-right: $spacer;
					margin-top: 50px;
					flex: 0 0 auto;
				}

				.stories-container{
					flex: 0 1 258px;
					margin-bottom: 100px;
					display: flex;
				}
			}

			.stories-container{

				.story{
					flex: 0 0 calc(240px + #{$spacer} / 2);
					padding: $spacer / 2 $spacer / 2 $spacer * 2;
					position: relative;
					border: 1px solid transparent;
					cursor: default;
					@include transition;

					@include media-breakpoint-down(md) {
						&:nth-child(2),
						&:nth-child(3){display: none;}
					}

					@include media-breakpoint-only(lg) {
						&:nth-child(3){display: none;}
					}

					&:hover{
						border-color: $light;

						.story-links{opacity: 1;}
					}

					.story-thumbnail{
						margin-bottom: $spacer;
					}

					.story-name{
						font-weight: 700;
						font-size: rem(18);
					}

					.story-job{
						text-transform: uppercase;
						font-size: rem(12);
						color: $secondary;
						line-height: 1;
					}

					.story-excerpt{
						color: $secondary;
						margin-top: $spacer / 2;
						font-size: rem(13);
					}

					.story-links{
						position: absolute;
						width: 200px;
						bottom: -15px;
						left: $spacer;
						opacity: 0;
						@include transition;

						.story-link{
							display: inline-block;

							a{
								display: flex;
								align-items: center;
								justify-content: center;
								width: 30px;
								height: 30px;
								border-radius: 30px;
								text-decoration: none;
							}

							&.see_more{

							}

							&.like{
								a{
									background-color: $salmon;
									color: $white;
									&:hover{background-color: darken($salmon, 10%);}
								}
							}

							&.share{
								a{
									background-color: $secondary;
									color: $white;
									&:hover{background-color: darken($secondary, 10%);}
								}
							}
						}
					}
				}
			}
		}
	}

	&.how_to{
		position: relative;
		z-index: 1;
		@include media-breakpoint-down(md) {padding: $spacer;}

		.how_to-wrapper{box-shadow: 0 2px 100px rgba(0,0,0,.1);}

		.how_to-overview{
			color: $white;
			@include media-breakpoint-down(md) {padding: $spacer * 2;}
			@include media-breakpoint-up(lg) {padding: $spacer * 4 $spacer * 2 $spacer * 2;}

			.title{
				h2{
					color: $dark;
					font-family: $font-family-script;
				}

				@include media-breakpoint-down(md) {
					h1{font-size: rem(22);}
					h2{font-size: rem(22); margin-top: $spacer * 2;}
				}

				@include media-breakpoint-up(lg) {
					h1{font-size: rem(24);}
					h2{margin-left: $spacer * 2; margin-top: $spacer * 3; font-size: rem(22);}
				}

				@include media-breakpoint-up(xl) {
					h1{font-size: rem(28);}
					h2{font-size: rem(28);}
				}
			}

			.en_savoir_plus{margin-top: $spacer * 3;}
		}

		.how_to-details{
			background-color: $white;
			@include media-breakpoint-down(md) {padding: $spacer * 2;}
			@include media-breakpoint-up(lg) {padding: $spacer * 4;}

			.title{
				font-size: rem(22);
				font-weight: lighter;
				margin-bottom: $spacer;
			}

			p{
				margin-bottom: $spacer * 3;
				color: $secondary;
				font-size: rem(13);
			}

			.row:last-child{
				p{margin-bottom: 0;}
			}
		}

		.how_to-slider{

			ul{
				list-style: none;
				margin: 0;
				padding: 0;

				li.how_to-slider-item{
					display: flex;

					.thumbnail{
						flex: 1 0 57px;
						width: 57px;
						margin-right: $spacer;

						@include media-breakpoint-up(lg) {
							margin-right: $spacer * 2;
						}
					}
				}
			}
		}

		.see_more{
			position: absolute;
			right: 20px;
			bottom: -$spacer;

			.btn{
				padding: $spacer / 2 $spacer * 3;
			}
		}
	}

	&.newsletter{
		padding: $spacer * 10 0;
		margin-top: -$spacer * 4;

		@include media-breakpoint-only(xs) {
			padding: $spacer * 3 0;
		}

		.newsletter-container{
			width: 85%;
			display: flex;
			margin: 0 auto;
			background-color: red;
		}

		.title{
			@include media-breakpoint-down(md) {text-align: center; margin-bottom: $spacer * 3;}
		}

		.register{
			label i{
				color: $salmon;
				font-size: rem(20);
				display: inline-block;
				vertical-align: middle;
				position: relative;
				top: -1px;
				margin-left: 10px;
				@include transition;
			}

			.input-filled{
				i{
					opacity: 0;
				}
			}

			.btn{
				width: 200px;
			}

			@include media-breakpoint-only(xs) {
				input,
				.input-group-append,
				.btn{
					width: 100%;
				}

				input{
					border-radius: 4px 4px 0 0;
				}

				.btn{
					border-radius: 0 0 4px 4px;
				}
			}
		}
	}
}
